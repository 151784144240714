import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userAnswersState } from '../recoil/atom';
import { IMAGES, TEXT } from '../contents/sourceUrl';
import Spacer from './spacer';
import useStopwatch from '../hooks/useStopwatch';
import Time from './time';

const TesterHeader: React.FC = () => {
  const userAnswersStateValue = useRecoilValue(userAnswersState);
  const [answerCount, setAnswerCount] = React.useState<number>(0);

  React.useEffect(() => {
    if (userAnswersStateValue) {
      const currentProgress = Array.from(userAnswersStateValue).filter(
        (item) => {
          return item[1] !== '';
        }
      );
      setAnswerCount(currentProgress.length);
    }
  }, [userAnswersStateValue]);

  return (
    <div id="header">
      <div className="headerContentBox">
        <img src={IMAGES.LOGO} alt="" width={220} />
        <Time />
        <div className="remainingBox">
          <div>
            <img src={IMAGES.BOOK_ICON} alt="" width={18} height={18} />
            <Spacer space={4} horizontal />
            <p>Remaining</p>
          </div>
          <div>
            <span>{answerCount}</span>
            <Spacer space={4} horizontal />
            <span>/</span>
            <Spacer space={4} horizontal />
            <span>{TEXT.QUESTIONS.length}</span>
          </div>
        </div>
      </div>
      <div className="statusBarWrap">
        <div
          style={{ width: (answerCount / TEXT.QUESTIONS.length) * 100 + '%' }}
        />
      </div>
    </div>
  );
};

export default TesterHeader;
