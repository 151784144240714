import React from 'react';
import TesterCam from './testerCam';
import { TtesterStatus } from '../types';
import useRtc from '../hooks/useRtc';
import { useNavigate } from 'react-router-dom';
import StunServerCheck from './test';

const TesterInfo: React.FC = () => {
  // const [videoResult, setVideoResult] = React.useState<TtesterStatus | null>(
  //   null
  // );
  const navigate = useNavigate();
  const [videoStream, setVideoStream] = React.useState<MediaStream | null>();
  const [testerStatus, setTesterStatus] = React.useState<
    '정상' | '부정행위' | '주의'
  >('정상');

  const { setStreamState, videoResult, setVideoResult } = useRtc(false);
  // console.log('rerander');
  const getMedia = async () => {
    try {
      const stream: MediaStream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      setStreamState(stream);
      setVideoStream(stream);
    } catch (error) {
      console.log(error);
    }
  };

  const getUserStatus = () => {
    if (videoResult) {
      let trueCount: number = 0;
      let falseKeys: string[] = [];

      for (let item in videoResult) {
        const key = item as keyof TtesterStatus;
        if (videoResult[key]) {
          trueCount++;
        } else {
          falseKeys.push(key);
        }
      }

      if (trueCount >= 3) {
        setTesterStatus('정상');
      } else if (trueCount <= 0) {
        setTesterStatus('부정행위');
      } else {
        setTesterStatus('주의');
      }

      return { trueCount, falseKeys };
    }
  };

  React.useEffect(() => {
    getUserStatus();
  }, [videoResult]);
  React.useEffect(() => {
    getMedia();
  }, []);

  return (
    <div id="testInfo">
      <div className="testInfoBox">
        <span>시험명</span>
        <span>기초상식</span>
        <span>응시자</span>
        <span>홍길동</span>
      </div>
      <div className="testerCurrentStatusContentWrap">
        <div className="testerCurrentStatusContentBox">
          <div
            style={{
              width: 10,
              height: 10,
              backgroundColor:
                testerStatus === '정상'
                  ? '#59C465'
                  : testerStatus === '주의'
                  ? '#F5C363'
                  : '#C83B38',
              borderRadius: 100,
            }}
          />
          <p>{testerStatus}</p>
        </div>
        <div>
          <TesterCam
            width={252}
            setVideoResult={setVideoResult}
            stream={videoStream}
          />
          <StunServerCheck />
        </div>
      </div>
    </div>
  );
};

export default TesterInfo;
