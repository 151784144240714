import React from 'react';

const useTimeCounter = (startTimeSec?: number) => {
  const [remainingTime, setRemainingTime] = React.useState<{
    hours: string | null;
    minutes: string | null;
    seconds: string | null;
  }>({
    hours: null,
    minutes: null,
    seconds: null,
  });

  const targetTime = '18:00:00';

  const calculateRemainingTime = () => {
    const now = new Date();
    const target = new Date();
    const targetSplit = targetTime.split(':');
    target.setHours(Number(targetSplit[0]));
    target.setMinutes(Number(targetSplit[1]));
    target.setSeconds(Number(targetSplit[2]));

    let timeDiff = target.getTime() - now.getTime();
    if (timeDiff < 0) {
      timeDiff += 24 * 60 * 60 * 1000; // 대상 시간이 이미 지난 경우 하루를 추가합니다.
    }

    const hours = Math.floor(timeDiff / (1000 * 60 * 60))
      .toString()
      .padStart(2, '0');
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60))
      .toString()
      .padStart(2, '0');
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000)
      .toString()
      .padStart(2, '0');

    setRemainingTime({ hours, minutes, seconds });
  };

  React.useEffect(() => {
    const id = setInterval(() => {
      calculateRemainingTime();
    }, 1000);
    return () => {
      clearInterval(id);
    };
  }, []);

  return remainingTime;
};

export default useTimeCounter;
