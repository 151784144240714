const Cheating_svg_2: React.FC<{ cheating: boolean }> = ({ cheating }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={cheating ? 'rgba(200, 59, 56, 0.2)' : 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="8"
        cy="8"
        r="7.5"
        stroke={cheating ? 'rgba(200, 59, 56, 1)' : '#C3C9C1'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8574 9.59838L13.7145 7.61889L10.8574 5.6394V6.85703L3.04785 6.85703V8.38084L10.8574 8.38084V9.59838Z"
        fill={cheating ? 'rgba(200, 59, 56, 1)' : '#C3C9C1'}
      />
    </svg>
  );
};

export default Cheating_svg_2;
