import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userAnswersState } from '../recoil/atom';
import { IMAGES, TEXT } from '../contents/sourceUrl';
import Spacer from './spacer';
import useStopwatch from '../hooks/useStopwatch';
import Time from './time';

const HostHeader: React.FC = () => {
  const userAnswersStateValue = useRecoilValue(userAnswersState);
  const [answerCount, setAnswerCount] = React.useState<number>(0);

  const icons: { [key: string]: string }[] = [
    {
      icon: IMAGES.DIRECTION_ICON_1,
      text: '얼굴방향',
    },
    {
      icon: IMAGES.DIRECTION_ICON_2,
      text: '시선방향',
    },
    {
      icon: IMAGES.DIRECTION_ICON_3,
      text: '곁눈질',
    },
  ];

  React.useEffect(() => {
    if (userAnswersStateValue) {
      const currentProgress = Array.from(userAnswersStateValue).filter(
        (item) => {
          return item[1] !== '';
        }
      );
      setAnswerCount(currentProgress.length);
    }
  }, [userAnswersStateValue]);

  return (
    <div
      id="header"
      style={{
        borderBottom: '1px solid rgba(215, 215, 215, 1)',
        alignItems: 'center',
      }}
    >
      <div className="headerContentBox">
        <p style={{ fontFamily: 'SemiBold', fontSize: 20, minWidth: '267px' }}>
          실시간 시험감독
        </p>
        <Time />
        <div
          style={{
            display: 'flex',
          }}
        >
          {icons.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  textAlign: 'center',
                  width: 89,
                }}
              >
                <img src={item.icon} alt="" />
                <p style={{ paddingTop: 5 }}>{item.text}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default HostHeader;
