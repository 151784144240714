import { IMAGES } from '../contents/sourceUrl';

const Footer: React.FC = () => {
  return (
    <div id="footer" className="footer">
      <div className="footerContainer">
        <div className="leftContent">
          <div className="logo">
            <img src={IMAGES.EDINTLOGO} alt="" width={99} />
          </div>
          <div className="discription">
            <p>(주) 에딘트 대표 : 원동일</p>
            <p>사업자 등록번호 : 462-81-02559</p>
            <p>통신판매번호 : 2023-대전유성-0826</p>
            <p>대전광역시 유성구 가정로 218, 3층 302-2-1호</p>
            <p>
              <span>이메일 : hello@edint.io</span>
              <span>전화 : 070-8065-0811</span>
            </p>
          </div>
        </div>
        <div />
        <div className="rightContent">
          <div className="copyright">
            <p> ⓒ 2022. EDINT Inc. All Rights Reserved</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
