import React from 'react';
import useStopwatch from '../hooks/useStopwatch';
import useTimeCounter from '../hooks/useTimeCounter';

const Time: React.FC = () => {
  const { hours, minutes, seconds } = useTimeCounter();

  if (hours != null && minutes != null && seconds != null)
    return (
      <div className="timeBox">
        <span>{hours}</span>
        <span>:</span>
        <span>{minutes}</span>
        <span>:</span>
        <span>{seconds}</span>
      </div>
    );
};

export default Time;
