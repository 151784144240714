//아 주석
const Spacer: React.FC<{ horizontal?: boolean; space: number }> = ({
  horizontal,
  space,
}) => {
  if (horizontal) {
    return <div style={{ marginLeft: space }} />;
  }
  return <div style={{ marginTop: space }} />;
};

export default Spacer;
