import { atom } from 'recoil';

const userAnswersState = atom({
  key: 'userAnswersState',
  default: null as Map<any, any> | null,
});

const atomTesterStatus = atom({
  key: 'atomTesterStatus',
  default: null as Map<any, any> | null,
});

export { userAnswersState, atomTesterStatus };
