const Cheating_svg_1: React.FC<{ cheating: boolean }> = ({ cheating }) => {
  //cheating:boolean
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={cheating ? 'rgba(200, 59, 56, 0.2)' : 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="8"
        cy="8"
        r="7.64204"
        stroke={cheating ? 'rgba(200, 59, 56, 1)' : '#C3C9C1'}
        strokeWidth="0.715913"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8708 8.38091C11.1342 8.83639 11.6267 9.14283 12.1907 9.14283C13.0323 9.14283 13.7145 8.4606 13.7145 7.61902C13.7145 6.77745 13.0323 6.09521 12.1907 6.09521C11.6267 6.09521 11.1343 6.40164 10.8708 6.8571L3.04785 6.8571L3.04785 8.38091L10.8708 8.38091Z"
        fill={cheating ? 'rgba(200, 59, 56, 1)' : '#C3C9C1'}
      />
    </svg>
  );
};

export default Cheating_svg_1;
