import { useRecoilValue } from 'recoil';
import Cheating_svg_1 from '../contents/images/cheating_svg_1';
import Cheating_svg_2 from '../contents/images/cheating_svg_2';
import Cheating_svg_3 from '../contents/images/cheating_svg_3';
import Spacer from './spacer';
import { atomTesterStatus } from '../recoil/atom';
import React, { useEffect } from 'react';

const TesterStatus: React.FC<{
  producerId: string;
  id: string;
  playBtnDisplay: boolean;
  //   boxRef: any;
}> = ({ producerId, id, playBtnDisplay }) => {
  //   console.log('testerStatusSocketId:', socketId);
  const atomTesterStatusValue = useRecoilValue(atomTesterStatus);
  //   console.log(atomTesterStatusValue);
  //   {att_gazing: true, att_heading: true, at
  const [currentStatus, setCurrentStatus] = React.useState<{
    att_gazing: boolean;
    att_heading: boolean;
    att_self: boolean;
  } | null>(null);
  React.useEffect(() => {
    if (atomTesterStatusValue) {
      atomTesterStatusValue.forEach((value, key) => {
        if (producerId == key) {
          //   console.log(key, value);
          setCurrentStatus(value);
        }
      });

      //   Object.keys(atomTesterStatusValue).map((item) => {
      //     console.log('여기볼꺼야!!!!!!', item);
      //     if (item === socketId) {
      //     }
      //   });
    }
  }, [atomTesterStatusValue]);

  //   console.log('boxRef:', boxRef);
  //   useEffect(() => {
  //     if (boxRef) {
  //     }

  //     // if (
  //     //   videoWrap &&
  //     //   currentStatus?.att_heading &&
  //     //   currentStatus?.att_gazing &&
  //     //   currentStatus?.att_gazing
  //     // ) {
  //     //   videoWrap?.style?.border =
  //     // }
  //   }, [boxRef]);

  return (
    <>
      <div
        style={{
          position: 'absolute',
          bottom: 5,
          left: 6,
          padding: 4,
          backgroundColor: '#fff',
          borderRadius: 100,
          display: 'flex',
        }}
      >
        <Cheating_svg_1 cheating={currentStatus?.att_heading ? false : true} />
        <Spacer horizontal space={6} />
        <Cheating_svg_2 cheating={currentStatus?.att_gazing ? false : true} />
        <Spacer horizontal space={6} />
        <Cheating_svg_3 cheating={currentStatus?.att_self ? false : true} />
      </div>

      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '136px',
          border:
            !currentStatus?.att_heading &&
            !currentStatus?.att_gazing &&
            !currentStatus?.att_gazing
              ? '2px solid rgba(200, 59, 56, 1)'
              : !currentStatus?.att_heading ||
                !currentStatus?.att_gazing ||
                !currentStatus?.att_gazing
              ? '2px solid  #F5C363'
              : undefined,
          top: 0,
          boxSizing: 'border-box',
          boxShadow:
            !currentStatus?.att_heading &&
            !currentStatus?.att_gazing &&
            !currentStatus?.att_gazing
              ? '0px 0px 8px 0px rgba(200, 59, 56, 1)'
              : !currentStatus?.att_heading ||
                !currentStatus?.att_gazing ||
                !currentStatus?.att_gazing
              ? '0px 0px 8px 0px #F5C363'
              : undefined,
          borderRadius: '4px',
        }}
      />
    </>
  );
};

export default TesterStatus;
