import React from 'react';
import Question from './question';
import { TEXT } from '../contents/sourceUrl';

const Questions: React.FC = () => {
  return (
    <div className="testPaper">
      {TEXT.QUESTIONS.map((item, idx) => {
        return (
          <div key={idx} className="questionBoxItem">
            <Question {...item} id={idx} />
          </div>
        );
      })}
    </div>
  );
};

export default Questions;
