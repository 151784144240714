import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Tester from './pages/tester';
import Host from './pages/host';
import { RecoilRoot } from 'recoil';
import io from 'socket.io-client';
import Test from './pages/test';

const App: React.FC = () => {
  return (
    <RecoilRoot>
      <Routes>
        <Route path="/" element={<Tester />} />
        <Route path="/Host" element={<Host />} />
        <Route path="/test" element={<Test />} />
      </Routes>
    </RecoilRoot>
  );
};

export default App;
