import { useRecoilValue } from 'recoil';
import Questions from '../components/questions';
import { userAnswersState } from '../recoil/atom';
import TesterHeader from '../components/testerHeader';
import StatusBar from '../components/statusBar';
import TesterInfo from '../components/testerInfo';
import Footer from '../components/footer';
import useTimeCounter from '../hooks/useTimeCounter';
import StunServerCheck from '../components/test';

const Tester: React.FC = () => {
  return (
    <div id="layout">
      <TesterHeader />
      <TesterInfo />
      <div className="questionsContentBox" id="questionsContent">
        <Questions />
      </div>
      <Footer />
    </div>
  );
};

export default Tester;
