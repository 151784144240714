import React from 'react';
import { TtesterStatus } from '../types';
import axios from 'axios';

const TesterCam: React.FC<{
  width: number;
  setVideoResult?: React.Dispatch<React.SetStateAction<TtesterStatus | null>>;
  stream?: MediaStream | null;
}> = ({ width, setVideoResult, stream }) => {
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const canvasRef = React.useRef<HTMLCanvasElement>(null);

  const imageDataRef = React.useRef<Blob | null>(null);
  const [imageData, setImageData] = React.useState<string | null>(null);
  const [intervalId_getImageUri, setIntervalId_getImageUri] =
    React.useState<NodeJS.Timeout | null>(null);

  const getVideoStatus = async (formData: FormData) => {
    try {
      const { data } = await axios.post(
        'https://e-ai.proctormatic.com/api/e',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      const response = JSON.parse(data.result);
      if (setVideoResult) setVideoResult({ ...response.attentions });
    } catch (error) {
      console.log(error);
    }
  };
  const getMedia = async () => {
    if (stream) {
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.width = width;
      }
    }
  };

  React.useEffect(() => {
    setInterval(() => {
      if (imageDataRef.current) {
        const formData = new FormData();
        formData.append('image', imageDataRef.current);
        getVideoStatus(formData);
      }
    }, 100);
  }, []);

  React.useEffect(() => {
    getMedia();
    if (stream) {
      if (canvasRef.current && videoRef.current) {
        const video = videoRef.current;
        const canvas = canvasRef.current;
        const context = canvas?.getContext('2d');
        const drawFrame = async () => {
          context?.drawImage(video, 0, 0, canvas.width, canvas.height);

          requestAnimationFrame(drawFrame);
          const dataUrl = canvas.toDataURL('image/jpeg');
          const blob = await fetch(dataUrl).then((res) => res.blob());
          imageDataRef.current = blob;
        };

        if (video) {
          video.addEventListener('loadeddata', () => {
            drawFrame();
          });

          // setIntervalId_getImageUri(intervalId_addImageUri);
        }

        return () => {
          if (video) {
            video.removeEventListener('loadeddata', () => {
              if (intervalId_getImageUri) clearInterval(intervalId_getImageUri);
            });
          }
        };
      }
    }
  }, [stream]);

  return (
    <div>
      <div className="testerVideoBox">
        <video
          ref={videoRef}
          autoPlay
          style={{ border: '1px solid', display: 'none' }}
        />
        <canvas ref={canvasRef} width={252} height={159} />
      </div>
    </div>
  );
};

export default TesterCam;
