import { questions } from '../contents/questions';

//image
import logoImage from './images/proctormaticLogo.png';
import edintLogoImage from './images/edintLogo.png';

//icon
import bookIcon from './images/bookIcon.png';
import attention from './images/directionIcon_attention.png';
import cheating from './images/directionIcon_cheating.png';
import face from './images/directionIcon_face.png';

const TEXT = {
  QUESTIONS: questions,
};

const IMAGES = {
  LOGO: logoImage,
  EDINTLOGO: edintLogoImage,
  BOOK_ICON: bookIcon,
  DIRECTION_ICON_1: face,
  DIRECTION_ICON_2: attention,
  DIRECTION_ICON_3: cheating,
};

export { TEXT, IMAGES };
