import io from 'socket.io-client';
import mediasoupClient, { Device } from 'mediasoup-client';
import React from 'react';
import { TtesterStatus } from '../types';

const SERVER_URL = 'https://dev.server.studycooky.kr';
// const SERVER_URL = 'http://localhost:8000';

const socket = io(SERVER_URL + '/sfu');

const iceServers = [
  { urls: 'stun:stun.l.google.com:19302' },
  { urls: 'stun:stun.l.google.com:5349' },
  { urls: 'stun:stun1.l.google.com:3478' },
  { urls: 'stun:stun1.l.google.com:5349' },
  { urls: 'stun:stun2.l.google.com:19302' },
  { urls: 'stun:stun2.l.google.com:5349' },
  { urls: 'stun:stun3.l.google.com:3478' },
  { urls: 'stun:stun3.l.google.com:5349' },
  { urls: 'stun:stun4.l.google.com:19302' },
  { urls: 'stun:stun4.l.google.com:5349' },
];

const iceTransportPolicy = 'all'; // or 'relay' if you are using TURN
const iceCandidatePoolSize = 0;

const rtcConfiguration = {
  iceServers,
  iceTransportPolicy,
  iceCandidatePoolSize,
};

const useRtc = (
  isHost: boolean,
  videoRef_?: React.RefObject<HTMLVideoElement>
) => {
  const [streamState, setStreamState] = React.useState<MediaStream | null>(
    null
  );
  const [videoResult, setVideoResult] = React.useState<TtesterStatus | null>(
    null
  );
  const [consumerId, setConsumerId] = React.useState<string | null>(null);
  const [isJoined, setIsJoined] = React.useState<boolean>(false);
  const videoRef = React.useRef<HTMLVideoElement | null>(null);
  const consumerIdRef = React.useRef();
  const testerTransportRef =
    React.useRef<mediasoupClient.types.Transport<mediasoupClient.types.AppData> | null>(
      null
    );
  // const [socket, setSocket] = React.useState<SocketIOClient.Socket>(
  //   io(SERVER_URL + '/sfu')
  // );

  // const canvasRef = React.useRef<HTMLCanvasElement>(null);

  const consume = ({ transport, producerId, rtpCapabilities }: any) => {
    socket.emit('transport-consume', {
      transportId: transport.id,
      producerId,
      rtpCapabilities,
    });
    socket.on('return-transport-consume', async ({ data }: any) => {
      const { params } = data;
      const consumeData = await transport.consume({
        ...params,
      });
      const stream = new MediaStream([consumeData.track]);
      setStreamState(stream);
      setConsumerId(consumeData.id);
    });
  };

  const createTransport = async (transportOptions: any) => {
    const device = new Device();
    await device.load({
      routerRtpCapabilities: transportOptions.rtpCapabilities,
    });

    socket.emit('create-webrtc-transport');
    socket.on('return-create-webrtc-transport', async (transportInfo: any) => {
      if (isHost) {
        const transport = device.createRecvTransport(transportInfo);
        // const producer = await transport.produceData();
        // console.log(producer);
        // console.log('transportOptions:', transport.c);
        transport.on(
          'connect',
          async ({ dtlsParameters }: any, callback, errback) => {
            console.log('tester!!!!!!!!');

            try {
              socket.emit('transport-connect', {
                transportId: transport.id,
                dtlsParameters,
              });
              console.log('Transport connected!', dtlsParameters);
              callback();
            } catch (error: any) {
              errback(error);
            }
          }
        );

        socket.on('new-producer', async ({ id }: any) => {
          socket.emit('create-webrtc-transport');
          consume({
            transport,
            producerId: id,
            rtpCapabilities: transportOptions.rtpCapabilities,
          });
        });
      } else {
        const transport = device.createSendTransport({
          ...transportInfo,
          ...rtcConfiguration,
        });
        testerTransportRef.current = transport;

        transport.on(
          'connect',
          async ({ dtlsParameters }: any, callback, errback) => {
            try {
              socket.emit('transport-connect', {
                transportId: transport.id,
                dtlsParameters,
              });
              console.log('Transport connected!', dtlsParameters);
              callback();
            } catch (error: any) {
              errback(error);
            }
          }
        );

        transport.on('produce', async (parameters, callback, errback) => {
          try {
            const data = socket.emit('transport-produce', {
              transportId: transport.id,
              kind: parameters.kind,
              rtpParameters: parameters.rtpParameters,
            });

            const { id } = data;
            console.log('produce실행완료', parameters);
            callback({ id });
          } catch (error: any) {
            errback(error);
          }
        });

        transport.produce({
          track: streamState?.getVideoTracks()[0],
          encodings: [
            { maxBitrate: 100000 },
            { maxBitrate: 300000 },
            { maxBitrate: 900000 },
          ],
          codecOptions: {
            videoGoogleStartBitrate: 1000,
          },
        });
      }
    });
  };

  const sendTesterStatus = () => {
    // {
    //   cheat: 3개 모두 false,
    //   Abnormal:얼굴방향만 true,
    //   normal:3개 모두 true
    //   }
    // 정상일때 ture가 반환 됨
    if (videoResult) {
      // console.log('이걸 그대로 보낼꺼야!!!!!', videoResult);
      // let statusArray: string[] = [];
      // let newStatus: string;
      // Object.keys(videoResult).map((item: any, idx: any) => {
      //   const key = item as keyof TtesterStatus;

      //   if (videoResult[key]) {
      //     statusArray.push(key);
      //   }
      // });
      // if (statusArray.length > 2) {
      //   //normal
      //   newStatus = 'normal';
      //   // console.log('status: ', 'normal');
      // } else if (statusArray.length > 1) {
      //   //abnormal
      //   newStatus = 'abnormal';
      //   // console.log('status: ', 'abnormal');
      // } else {
      //   //cheat
      //   newStatus = 'cheat';
      //   // console.log('status: ', 'cheat');
      // }
      // console.log('status: ', newStatus);

      // const newStatus = videoResult.toString();
      const newStatus = JSON.stringify(videoResult);
      socket.emit('tester-status', { status: newStatus });
    }
  };
  // sendTesterStatus();
  const joinServer = () => {
    socket.emit('join', { isHost });
    socket.on(
      'return-join',
      (joined: {
        existTesters: boolean;
        isHost: boolean;
        isSuccess: boolean;
      }) => {
        console.log('join: ', joined);
        if (joined.isSuccess) {
          setIsJoined(true);

          socket.emit('get-router-rtp-capabilities');
          socket.on(
            'return-get-router-rtp-capabilities',
            async (rtpCapabilities: any) => {
              // rtpCapabilitiesRef.current = rtpCapabilities;
              createTransport(rtpCapabilities);
            }
          );
        }
      }
    );
  };

  React.useEffect(() => {
    if (!isJoined) {
      if (isHost) {
        console.log('추최자페이지에 접근함');
        joinServer();
      } else {
        if (streamState) {
          joinServer();
          console.log('테스터페이지에 접근함');
        }
      }
    }
  }, [streamState, isHost]);

  React.useEffect(() => {
    if (streamState) {
      const videoEle = videoRef?.current;
      if (videoEle) {
        videoEle.srcObject = streamState;
        videoEle.width = 200;

        socket.emit('resume', { consumerId });
      }
    }
  }, [videoRef, streamState, consumerId]);

  const testerExit = () => {
    //영상을 송신하는 화면에서 이탈했음을 서버에게 알림
    socket.emit('exit');

    //트랜스포트 연결해지
    if (testerTransportRef.current) {
      testerTransportRef.current.close();
    }
    //소켓 연결해제
    setTimeout(() => {
      socket.disconnect();
    }, 1500);
  };

  window.addEventListener('beforeunload', testerExit);
  window.addEventListener('offline', testerExit);

  React.useEffect(() => {
    return () => {
      testerExit();
    };
  }, []);

  React.useEffect(() => {
    sendTesterStatus();
  }, [videoResult]);

  return {
    setStreamState,
    streamState,
    videoRef,
    videoResult,
    setVideoResult,
  };

  //   React.useEffect(() => {
  //     setTimeout(() => {
  //       createConsumerTransport(rtpCapabilitiesRef.current);
  //     }, 3000);
  //   }, []);

  //////여기서부터////////
  //  React.useEffect(() => {
  //     // const initialize = async () => {
  //     //   // 소켓 연결 설정
  //     const socketIo = io.connect(SERVER_URL + '/sfu');

  //     //   // 소켄 연결
  //     //   socketIo.emit('join', { isHost: false });
  //     //   socketIo.on('return-join', (joined: boolean) => {
  //     //     console.log('joined:', joined);
  //     //   });

  //     //   //디바이스 로드
  //     //   const device = new Device();
  //     //   socketIo.emit('get-router-rtp-capabilities');
  //     //   socketIo.on(
  //     //     'return-get-router-rtp-capabilities',
  //     //     async ({ rtpCapabilities }: any) => {
  //     //       device.load({ routerRtpCapabilities: rtpCapabilities });
  //     //       //    await device.createSendTransport(socketIo);
  //     //       socketIo.on(
  //     //         'return-create-webrtc-transport',
  //     //         async (transportInfo: any) => {
  //     //           const transport = device.createSendTransport(transportInfo);
  //     //           console.log('transport', transport);
  //     //           // transport.on('connect', (a: any) => {
  //     //           //   console.log(a);
  //     //           // });
  //     //         }
  //     //       );
  //     //     }
  //     //   );

  //     //   //트랜스포트 생성
  //     // };

  //     // initialize();

  //     let device: any;
  //     socketIo.emit('join', { isHost: false });
  //     socketIo.on('return-join', (joined: boolean) => {
  //       console.log('joined:', joined);
  //     });
  //     socketIo.emit('get-router-rtp-capabilities');
  //     socketIo.on(
  //       'return-get-router-rtp-capabilities',
  //       async ({ rtpCapabilities }: any) => {
  //         device = new Device();
  //         await device.load({ routerRtpCapabilities: rtpCapabilities });
  //       }
  //     );
  //     socketIo.emit('create-webrtc-transport');
  //     socketIo.on(
  //       'return-create-webrtc-transport',
  //       async (transportInfo: any) => {
  //         const transport = await device.createSendTransport(transportInfo);
  //         transport.on('connect', async (dtlsParameters: any) => {
  //           console.log('Producer rtpParameters:', dtlsParameters);
  //           console.log('DTLS Parameters:', dtlsParameters);

  //           // 여기서 프로듀서 생성과 rtpParameters를 사용하여 미디어 스트림 전송을 설정할 수 있습니다.
  //         });
  //         console.log(transport);
  //         socketIo.emit('transport-connect', {
  //           transportId: transportInfo.id,
  //           dtlsParameters: transportInfo.dtlsParameters,
  //         });
  //       }
  //     );
  //   }, []);
};
export default useRtc;
// console.log('transport', transportInfo.dtlsParameters);
