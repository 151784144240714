import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import * as mediasoupClient from 'mediasoup-client';

const StunServerCheck = () => {
  const [stunServerStatus, setStunServerStatus] = useState('Checking...');
  const stunServer = 'stun:stun.l.google.com:19302'; // Example STUN server

  const checkStunServer = async () => {
    const configuration = {
      iceServers: [
        { urls: 'stun:stun.l.google.com:19302' },
        { urls: 'stun:stun.l.google.com:5349' },
        { urls: 'stun:stun1.l.google.com:3478' },
        { urls: 'stun:stun1.l.google.com:5349' },
        { urls: 'stun:stun2.l.google.com:19302' },
        { urls: 'stun:stun2.l.google.com:5349' },
        { urls: 'stun:stun3.l.google.com:3478' },
        { urls: 'stun:stun3.l.google.com:5349' },
        { urls: 'stun:stun4.l.google.com:19302' },
        { urls: 'stun:stun4.l.google.com:5349' },
      ],
    };

    const peerConnection = new RTCPeerConnection(configuration);

    peerConnection.onicecandidate = (event) => {
      if (event.candidate) {
        console.log('ICE candidate gathered:', event.candidate);
        setStunServerStatus('STUN server is operational');
        peerConnection.close();
      }
    };

    peerConnection.onicegatheringstatechange = () => {
      if (peerConnection.iceGatheringState === 'complete') {
        if (stunServerStatus !== 'STUN server is operational') {
          setStunServerStatus('Failed to gather ICE candidates');
        }
        peerConnection.close();
      }
    };

    // Create a dummy data channel to start the ICE gathering process
    peerConnection.createDataChannel('dummy');

    try {
      const offer = await peerConnection.createOffer();
      await peerConnection.setLocalDescription(offer);
    } catch (error) {
      console.error('Error during STUN server check:', error);
      setStunServerStatus('Error checking STUN server');
      peerConnection.close();
    }
  };

  useEffect(() => {
    checkStunServer();
  }, []);

  return (
    <div>
      <h1>STUN Server Check</h1>
      <p>Status: {stunServerStatus}</p>
    </div>
  );
};

export default StunServerCheck;
