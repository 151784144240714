import React from 'react';
import HostHeader from '../components/hostHeader';
import io from 'socket.io-client';
import mediasoupClient, { Device } from 'mediasoup-client';
import Footer from '../components/footer';
import { useSetRecoilState } from 'recoil';
import { atomTesterStatus } from '../recoil/atom';
import TesterStatus from '../components/testerStatus';
import StunServerCheck from '../components/test';

const SERVER_URL = 'https://dev.server.studycooky.kr';
// const SERVER_URL = 'http://localhost:8000';

let socket: SocketIOClient.Socket;

const iceServers = [
  { urls: 'stun:stun.l.google.com:19302' },
  { urls: 'stun:stun.l.google.com:5349' },
  { urls: 'stun:stun1.l.google.com:3478' },
  { urls: 'stun:stun1.l.google.com:5349' },
  { urls: 'stun:stun2.l.google.com:19302' },
  { urls: 'stun:stun2.l.google.com:5349' },
  { urls: 'stun:stun3.l.google.com:3478' },
  { urls: 'stun:stun3.l.google.com:5349' },
  { urls: 'stun:stun4.l.google.com:19302' },
  { urls: 'stun:stun4.l.google.com:5349' },
];

const iceTransportPolicy = 'all'; // or 'relay' if you are using TURN
const iceCandidatePoolSize = 0;

const rtcConfiguration = {
  iceServers,
  iceTransportPolicy,
  iceCandidatePoolSize,
};

const Host: React.FC = () => {
  let device: any;
  const playBtnRef = React.useRef<(HTMLButtonElement | null)[]>([]);
  const [playBtnDisplay, setPlayBtnDisplay] = React.useState<boolean>(true);
  const [consumers, setConsumers] = React.useState<any>([]);
  const setAtomTesterStatus = useSetRecoilState(atomTesterStatus);
  // const [testerStatus, setTesterStatus] = React.useState<Map<any, any>>(
  //   new Map()
  // );

  const hostTransportRef =
    React.useRef<mediasoupClient.types.Transport<mediasoupClient.types.AppData> | null>(
      null
    );

  const testerStatus = React.useRef<Map<any, any>>(new Map());
  const getNewConsume = (
    transportId: string,
    producerId: any,
    rtpCapabilities: any
  ) => {
    socket.emit('transport-consume', {
      transportId,
      producerId,
      rtpCapabilities,
    });
  };

  // 새로운 항목 추가하는 함수
  const addItem = (key: string, value: string) => {
    const newDataMap = new Map(testerStatus.current);
    const newValue = JSON.parse(value);
    newDataMap.set(key, newValue);
    // setTesterStatus(newDataMap);
    testerStatus.current = newDataMap;
  };
  // console.log('testerStatus:', testerStatus);
  // // 특정 항목을 가져오는 함수
  // const getItem = (key) => {
  //   return dataMap.get(key);
  // };

  // // 특정 항목을 업데이트하는 함수
  // const updateItem = (key, newValue) => {
  //   const newDataMap = new Map(testerStatus);
  //   newDataMap.set(key, newValue);
  //   setDataMap(newDataMap);
  // };

  /////📌

  const hostExit = () => {
    //영상을 송신하는 화면에서 이탈했음을 서버에게 알림
    socket.emit('exit');

    //트랜스포트 연결해지
    if (hostTransportRef.current) {
      hostTransportRef.current.close();
    }
    //소켓 연결해제
    setTimeout(() => {
      socket.disconnect();
    }, 1500);
  };

  window.addEventListener('offline', hostExit);
  window.addEventListener('beforeunload', hostExit);

  React.useEffect(() => {
    return () => {
      hostExit();
    };
  }, []);

  /////📌
  React.useEffect(() => {
    setInterval(() => {
      setAtomTesterStatus(testerStatus.current);
      // console.log('testerStatus.current:', testerStatus.current);
    }, 200);
  }, []);
  React.useEffect(() => {
    socket = io(SERVER_URL + '/sfu');
    socket.emit('join', { isHost: true });
    socket.on(
      'return-join',
      (userData: {
        existTesters: { id: string; producerId: string }[];
        isHost: boolean;
        isSuccess: boolean;
      }) => {
        console.log('userData', userData);
        if (userData.isSuccess) {
          socket.emit('get-router-rtp-capabilities');
          socket.on(
            'return-get-router-rtp-capabilities',
            async (rtp_Capabilities: any) => {
              device = new Device();
              await device.load({
                routerRtpCapabilities: rtp_Capabilities.rtpCapabilities,
              });

              ////////여기

              socket.emit('create-webrtc-transport');
              socket.on(
                'return-create-webrtc-transport',
                async (transportInfo: any) => {
                  const transport: mediasoupClient.types.Transport<mediasoupClient.types.AppData> =
                    device.createRecvTransport({
                      ...transportInfo,
                      ...rtcConfiguration,
                    });
                  hostTransportRef.current = transport;
                  transport.on(
                    'connect',
                    async ({ dtlsParameters }, callback, errback) => {
                      try {
                        socket.emit('transport-connect', {
                          transportId: transport.id,
                          dtlsParameters,
                        });
                        // console.log('Transport connected!', dtlsParameters);
                        callback();
                      } catch (error: any) {
                        errback(error);
                      }
                    }
                  );
                  //

                  if (userData.existTesters.length > 0) {
                    userData.existTesters.map((item) => {
                      // console.log('!!!!!!!!!!!!!!!!!!', item);
                      getNewConsume(
                        transport.id,
                        item.producerId,
                        rtp_Capabilities.rtpCapabilities
                      );
                    });
                  }
                  socket.on('new-producer', async ({ id }: any) => {
                    getNewConsume(
                      transport.id,
                      id,
                      rtp_Capabilities.rtpCapabilities
                    );
                    // socket.emit('transport-consume', {
                    //   transportId: transport.id,
                    //   producerId: id,
                    //   rtpCapabilities: rtp_Capabilities.rtpCapabilities,
                    // });
                  });
                  socket.on(
                    'return-transport-consume',
                    async ({ data }: any) => {
                      const { params } = data;
                      const consumeData = await transport.consume({
                        ...params,
                      });
                      // const stream = new MediaStream([consumeData.track]);
                      // console.log('consumeData:', socketId);
                      const stream = new MediaStream();
                      stream.addTrack(consumeData.track);
                      // console.log(consumeData.producerId);

                      setConsumers((prev: MediaStream[] | []) => [
                        ...prev,
                        {
                          stream,
                          producerId: consumeData.producerId,
                        },
                      ]);
                      socket.emit('resume', {
                        consumerId: consumeData.id,
                      });
                    }
                  );
                }
              );
              socket.on(
                'get-updated-tester-status',
                ({ producerId, status }: { [key: string]: string }) => {
                  addItem(producerId, status);
                }
              );
            }
          );
          socket.on('exit-producer', (data: any) => {
            setConsumers((prev: MediaStream[] | []) =>
              prev.filter((item: any) => {
                // console.log('item:', item.stream.id);
                return item.producerId !== data.producerId;
              })
            );
          });
        }
      }
    );
    socket.on('disconnect', () => {
      console.log('연결 끊김');
    });
  }, []);
  const play = () => {
    playBtnRef.current.forEach((element: any) => {
      element.click();

      setPlayBtnDisplay(false);
    });
  };
  // const [test, setTest] = React.useState<any>();
  return (
    <div id="layout">
      <HostHeader />
      {consumers.length <= 0 ? (
        <div
          style={{
            backgroundColor: '#f4f4f4',
            gridColumn: '1 / -1',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p
            style={{
              fontFamily: 'SemiBold',
              color: 'darkgray',
            }}
          >
            접속중인 응시자가 존재하지 않습니다.
          </p>
        </div>
      ) : (
        <div
          style={{
            backgroundColor: '#F8F8F8',
            gridColumn: '1 / -1',
            position: 'relative',
          }}
        >
          {/* {consumers.length > 0 && playBtnDisplay ? (
            <div
              style={{
                position: 'absolute',
                top: 0,
                width: '100%',
                height: '100%',
                zIndex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  zIndex: -1,
                  top: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: '#f4f4f4',
                }}
              />
  

              <p
                style={{
                  fontFamily: 'Medium',
                  fontSize: '18px',
                }}
              >
                접속중인 응시자: {consumers.length}명
              </p>
              <p
                onClick={play}
                style={{
                  fontFamily: 'SemiBold',
                  fontSize: '14px',
                  color: 'gray',
                  marginTop: 20,
                  border: '1px solid darkgray',
                  padding: 10,
                  borderRadius: 4,
                  cursor: 'pointer',
                }}
              >
                실시간 응시자 모습 확인
              </p>
            </div>
          ) : null} */}
          <div
            style={{
              margin: '24px 32px',
              height: '100%',
              // backgroundColor: 'red',
              boxSizing: 'border-box',
            }}
          >
            <div
              style={{
                maxWidth: '1144px',
                margin: 'auto',
              }}
            >
              <div>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateRows: 'repeat(3, 1fr)',
                    gridTemplateColumns: 'repeat(5, 1fr)',
                    gap: '16px',
                  }}
                >
                  <StunServerCheck />

                  {consumers.map(
                    (
                      item: { stream: MediaStream; producerId: string },
                      idx: string
                    ) => {
                      // console.log('item', item.socketId);
                      // let videoBoxRef: any = null;
                      let videoRef: any = null;
                      return (
                        <div
                          key={idx}
                          id={'video' + idx}
                          ref={(videoBoxEle) => {
                            if (videoBoxEle) {
                              // setTest(videoBoxEle);
                            }
                          }}
                          style={{
                            width: '100%',
                            height: '136px',

                            position: 'relative',
                            // boxSizing: 'border-box',
                            // boxShadow: '0px 0px 8px 0px #F00',
                          }}
                        >
                          <div
                            style={{
                              overflow: 'hidden',
                              width: '100%',
                              height: '136px',
                              // border: '2px solid rgba(200, 59, 56, 1)',
                              borderRadius: '4px',
                            }}
                          >
                            <video
                              autoPlay
                              playsInline
                              muted //
                              preload="metadata"
                              ref={(videoEle) => {
                                if (videoEle) {
                                  videoEle.srcObject = item.stream;
                                  videoRef = videoEle;
                                  videoRef.pause();
                                  videoRef.muted = true;
                                  setTimeout(() => {
                                    try {
                                      const canPlay =
                                        videoRef.canPlayType('video');
                                      if (videoRef.srcObject) {
                                        const play = videoRef.play();
                                        console.log('play: ', play);
                                      }
                                      console.log('canPlay: ', canPlay);
                                    } catch (error) {
                                      console.log('playErr: ', error);
                                    }
                                  }, 1000);
                                }
                              }}
                              style={{
                                width: '100%',
                                borderRadius: '4px',
                              }}
                              onLoadedMetadata={(e) => {
                                console.log('onLoadedMetadata:', e);
                              }}
                            />
                          </div>
                          <TesterStatus
                            producerId={item.producerId}
                            id={'video' + idx}
                            playBtnDisplay={playBtnDisplay}
                            // boxRef={test}
                          />
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default Host;
