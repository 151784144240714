const Cheating_svg_3: React.FC<{ cheating: boolean }> = ({ cheating }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={cheating ? 'rgba(200, 59, 56, 0.2)' : 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="8"
        cy="8"
        r="7.64204"
        stroke={cheating ? 'rgba(200, 59, 56, 1)' : '#C3C9C1'}
        strokeWidth="0.715913"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4288 8.6324L13.7145 6.98282L11.4288 5.33325V6.19584L3.04788 6.19584L3.04788 6.22092L3.04785 6.2209L3.04788 7.50156L3.04788 7.71965H3.04789L3.04789 7.74475L9.25747 10.977C9.28806 11.4357 9.56949 11.861 10.0214 12.0534C10.6617 12.326 11.4017 12.028 11.6743 11.3877C11.9469 10.7474 11.6489 10.0074 11.0086 9.7348C10.6113 9.56561 10.1755 9.61622 9.83685 9.83202L5.86554 7.71965L11.4288 7.71965V8.6324Z"
        fill={cheating ? 'rgba(200, 59, 56, 1)' : '#C3C9C1'}
      />
    </svg>
  );
};

export default Cheating_svg_3;
